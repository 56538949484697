/* container for the 404 page */
.success-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f8f8;

  /* styling for the title */
  .success-title {
    font-size: 6rem;
    font-weight: 600;
    color: #b0bec5;
    margin-bottom: 1rem;
  }

  /* styling for the subtitle */
  .success-subtitle {
    font-size: 2.5rem;
    font-weight: 500;
    color: #546e7a;
    margin-bottom: 2rem;
  }
}
