.first-login-page {
  width: 500px;

  .submit-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
