.page--data-deletion {
    .data-deletion-div {
        text-align: center;
        width: calc(633px - 24px - 24px);
        height: fit-content;
        padding: 40px 24px;
        background-color: var(--color-white);
        border: 0px solid transparent;
        border-radius: 0px;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 8px;

        h2 {
            text-align: center;
            margin-bottom: 32px;
        }

     
        .title {
            text-align: left;
            font-size: 20px;
            font-weight: 700;
            line-height: 20px;
           
        }

        .sub-title {
            text-align: left;
            font-size: 20px;
            font-weight: 400;
            line-height: 20px;
           
        }

        .description {
            text-align: left;
            font-size: 19px;
            font-weight: 400;
            line-height: 20px;

            margin-top: 40px;
           
        }

    }
}
