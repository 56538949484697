.login-layout {
  width: 100vw;
  background-color: var(--color-blue);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;

  .forgot-password-link {
    margin-top: 10px;
    cursor: pointer;
    text-decoration: underline;
    font-family: var(--font-primary);
    &:hover {
      color: var(--color-highlight);
    }
  }
}
