@font-face {
  font-family: "Puskas Bai Jamjuree";
  font-style: italic;
  font-weight: 100;
  src: url("../font/BaiJamjuree-Italic.ttf") format("opentype");
}

@font-face {
  font-family: "Puskas Bai Jamjuree";
  font-style: normal;
  font-weight: 200;
  src: url("../font/BaiJamjuree-ExtraLight.ttf") format("opentype");
}

@font-face {
  font-family: "Puskas Bai Jamjuree";
  font-style: italic;
  font-weight: 200;
  src: url("../font/BaiJamjuree-ExtraLightItalic.ttf") format("opentype");
}

@font-face {
  font-family: "Puskas Bai Jamjuree";
  font-style: normal;
  font-weight: 300;
  src: url("../font/BaiJamjuree-Light.ttf") format("opentype");
}

@font-face {
  font-family: "Puskas Bai Jamjuree";
  font-style: italic;
  font-weight: 300;
  src: url("../font/BaiJamjuree-LightItalic.ttf") format("opentype");
}

@font-face {
  font-family: "Puskas Bai Jamjuree";
  font-style: normal;
  font-weight: 400;
  src: url("../font/BaiJamjuree-Regular.ttf") format("opentype");
}

@font-face {
  font-family: "Puskas Bai Jamjuree";
  font-style: normal;
  font-weight: 600;
  src: url("../font/BaiJamjuree-SemiBold.ttf") format("opentype");
}

@font-face {
  font-family: "Puskas Bai Jamjuree";
  font-style: italic;
  font-weight: 600;
  src: url("../font/BaiJamjuree-SemiBoldItalic.ttf") format("opentype");
}

@font-face {
  font-family: "Puskas Bai Jamjuree";
  font-style: normal;
  font-weight: 700;
  src: url("../font/BaiJamjuree-Bold.ttf") format("opentype");
}

@font-face {
  font-family: "Puskas Bai Jamjuree";
  font-style: italic;
  font-weight: 700;
  src: url("../font/BaiJamjuree-BoldItalic.ttf") format("opentype");
}

@font-face {
  font-family: "Puskas Bai Jamjuree";
  font-style: normal;
  font-weight: 900;
  src: url("../font/BaiJamjuree-Medium.ttf") format("opentype");
}

@font-face {
  font-family: "Puskas Bai Jamjuree";
  font-style: italic;
  font-weight: 900;
  src: url("../font/BaiJamjuree-MediumItalic.ttf") format("opentype");
}
