/* container for the 404 page */
.error-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f8f8;

  /* styling for the title */
  .error-title {
    font-size: 6rem;
    font-weight: 600;
    color: #b0bec5;
    margin-bottom: 1rem;
  }

  /* styling for the subtitle */
  .error-subtitle {
    font-size: 2.5rem;
    font-weight: 500;
    color: #546e7a;
    margin-bottom: 2rem;
  }

  /* styling for the link */
  .error-link {
    font-size: 1.5rem;
    font-weight: 500;
    color: #3f51b5;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
    margin-top: 15px;
    /* hover effect for the link */
    &:hover {
      color: #7986cb;
      cursor: pointer;
    }
  }
}
