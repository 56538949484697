.language-selector {
  margin-left: 50px;
  cursor: pointer;
  color: var(--font-color);
  font-weight: 700;
  margin-right: 10px;
  @media (max-width: 776px) {
    margin-left: 20px;
  }
}
