.admin-page {
  background-color: var(--color-primary);
  width: var(--page-width);
  margin-top: 30px;
  opacity: 0.8;
  padding: 32px;
  border-radius: 32px;
  margin-bottom: 30px;

  .button-container {
    margin-bottom: 15px;
    position: relative;
    float: right;
  }
}
